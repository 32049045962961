import React from "react";

export const Block = ({title, children}) => (
  <div className="mt-3 mb-1 bg-white shadow rounded-lg flex flex-col gap-2 p-3 sm:p-6">
    <dt className="text-lg font-medium text-gray-500">
      {title}
    </dt>
    <div>
      {children}
    </div>
  </div>
)