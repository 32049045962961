import {ExclamationCircleIcon} from "@heroicons/react/outline";
import React from "react";

export const NotAvailable = () => (
  <div className="w-full h-56 bg-gray-100 flex items-center justify-center p-8 text-gray-500">
    <div className="flex flex-col gap-2 items-center">
      <div className="flex flex-row gap-1">
        <ExclamationCircleIcon className="w-6" />
        <div className="text-lg font-semibold">
          Dati non disponibili
        </div>
      </div>
      <div>
        Riprova inserendo una combinazione di parametri diversa
      </div>
    </div>
  </div>
)