import {Block} from "../Block";
import {PageSection} from "../../../../../ui-components/Container";
import React from "react";
import {computeKpi} from "./lib/utils";
import {prepareData} from "../Table/lib/utils";
import {Table} from "../Table";


export const Summary = ({data, outlook}) => {

  // utilities
  const kpi1 = computeKpi(data, outlook);
  const renderSectionTitle = (title, color) => (
    <div className="flex items-center gap-1">
      <div
        className={`inline-block mr-1.5 rounded-full w-2 ${color}`}
        style={{aspectRatio: "1"}}
      />
      <p>{title}</p>
    </div>
  )
  const formatPercentage = (value) => {
    const options = {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    return Intl.NumberFormat('it-IT', options).format(value);
  }

  return (
    <div className="flex flex-col gap-5">
      <Block title="KPI">
        (Recupero diretto + Indiretto consegnato) / Sollecitato consegnato ={" "}
        <span className="font-semibold">
          {kpi1 !== null ? formatPercentage(kpi1) : "N/A"}
        </span>
      </Block>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-7 justify-stretch">
        <PageSection title={renderSectionTitle("Sollecitato", "bg-yellow-400")} containerCls="">
          <Table data={prepareData(
            data,
            {
              "Sollecitato": (row) => row.val_total_non_unique,
              "Sollecitato univoco": (row) => row.val_total_unique,
              "Sollecitato univoco consegnato": (row) => row.val_consegnato_unique,
            },
          )} />
        </PageSection>
        <PageSection title={renderSectionTitle("Recuperato", "bg-green-500")} containerCls="">
          <Table data={prepareData(
            data,
            {
              "Diretto": (row) => row.val_direct,
              "Diretto + indiretto consegnato": (row) => row.val_indirect[outlook] !== undefined
                ? row.val_direct + row.val_indirect[outlook]
                : null,
            },
          )} />
        </PageSection>
      </div>
      <Block title="Legenda">
        <p><span className="font-semibold">Diretto</span>: pagamento avvenuto attraverso il link di pagamento allegato al sollecito</p>
        <p><span className="font-semibold">Indiretto</span>: pagamento avvenuto attraverso altre modalità entro {outlook} giorni dalla data di invio del sollecito</p>
      </Block>
    </div>
  )
}