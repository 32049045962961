import {useEffect, useState} from "react";
import ApiService from "../../../../services/ApiService";


/**
 * Fetches the outlook data for the given number of past months (i.e. 1, 3, 6 or 12).
 *
 * @param months - the number of past months to consider
 * @returns {{data: undefined | any[], loading: boolean}} - the outlook data and a flag indicating whether the data is being fetched
 */
export const useOutlookData = (months) => {

  // state
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // handlers
  useEffect(() => {
    setLoading(true);
    ApiService.getApi(`analytics/outlook/${months}`)
      .then(setData)
      .catch(() => {
        console.error("Error when fetching outlook");
      })
      .finally(() => setLoading(false));
  }, [months]);

  return {data, loading};

}