import React, {useState} from "react";
import {PageHeading} from "../../../ui-components/Container";
import Select from "../../../ui-components/Select";
import {Summary} from "./components/Summary";
import {MONTHS_CHOICES, OUTLOOK_CHOICES} from "./lib/consts";
import {useOutlookData} from "./hooks/useOutlookData";
import {NotAvailable} from "./components/NotAvailable";
import Placeholder from "../../../ui-components/Placeholder";


export const PaymentOutlooks = () => {

  // state
  const [months, setMonths] = useState(1);
  const [outlook, setOutlook] = useState(5);

  // hooks
  const {data, loading} = useOutlookData(months);

  // utilities
  const buildOptions = (choices) =>
    Object.entries(choices)
      .map(([value, name]) => ({value, name}));

  let content = <Placeholder height="h-52" />;
  if (!loading)
    content = data?.length > 0
      ? <Summary data={data} outlook={outlook} />
      : <NotAvailable />;

  return <>
    <PageHeading
      title="Outlook pagamenti"
      contentActions={
        <div className="flex items-center gap-3">
          <Select
            options={buildOptions(MONTHS_CHOICES)}
            value={months}
            onChange={(e) => {setMonths(e.target.value)}}
            label="Orizzonte temporale"
          />
          <Select
            options={buildOptions(OUTLOOK_CHOICES)}
            value={outlook}
            onChange={(e) => {setOutlook(e.target.value)}}
            label="Outlook"
          />
        </div>
      }
    />
    <div className="mb-8">
      {content}
    </div>
  </>
}