export const COLUMNS = [
  {
    title: "",
    field: "label",
  },
  {
    title: "Scaduto",
    field: "val_expired",
  },
  {
    title: "A scadere",
    field: "val_expiring",
  },
  {
    title: "Totale",
    field: "val_total",
  },
].map((col) => ({
  ...col,
  ...(col.field === "label" ? {} : {type: "currency"}),
  render: (rowData) => {
    const value = rowData[col.field];
    if (col.field === "label")
      return <span className="font-semibold">{value}</span>;

    return value !== null
      ? Intl.NumberFormat("it-IT", {style: "currency", currency: "EUR"}).format(rowData[col.field])
      : "N/A";
  },
}));

export const OPTIONS = {
  filtering: false,
  sorting: false,
  draggable: false,
  paging: false,
  showTitle: false,
  toolbar: false,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    emptyDataSourceMessage: "Nessun dato disponibile",
  },
};