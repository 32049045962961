/**
 * Prepare data for outlook tables
 *
 * @param data - raw outlook data returned from the API
 * @param rowMappings - object containing specifications on how to map the raw data to the table's rows. Such object
 * should contain as many entries as the number of rows in the table. Each entry should have the following structure:
 * - key: the label of the row
 * - value: a function that takes a row of the raw data and returns the value to be displayed in the row. This function
 * will be used to compute the value of "val_expired" and "val_expiring" of each row.
 * @returns {Array} - an array of objects, each representing a row of the table. Each object must have the following
 * structure: label, val_expired, val_expiring, val_total.
 */
export const prepareData = (data, rowMappings) => !!data
  ? Object.entries(rowMappings).reduce(
    (acc, [label, fn]) => {
      const row = {label};
      ["val_expired", "val_expiring"].forEach((field) => {
        const expiringFlagValue = field === "val_expiring";
        const dataRow = data.find((row) => row.flg_a_scadere === expiringFlagValue);
        if (!dataRow) {
          console.warn("Target row not found in data (flg_a_scadere: " + expiringFlagValue + ")");
          return;
        }
        row[field] = fn(dataRow);
      });
      row["val_total"] = row["val_expired"] !== null && row["val_expired"] !== null
        ? row["val_expired"] + row["val_expiring"]
        : null;
      return [...acc, row];
    },
    []
  )
  : null;
