/**
 * Compute the KPI value to be displayed in the Summary component
 *
 * @param data - raw outlook data returned from the API
 * @param outlook - the selected number of outlook days (i.e. 5, 10 or 20)
 * @returns {number|null} - The computed KPI value, or null if the data is not available
 */
export const computeKpi = (data, outlook) => {
  if (!data)
    return null;
  const {direct, indirect, solicited} = data.reduce(
    (acc, curr) => {
      // 1. Update direct value
      acc.direct += curr.val_direct;
      // 2. Update indirect value
      const value = curr.val_indirect[outlook];
      if (value !== undefined) {
        if (acc.indirect === null)
          acc.indirect = 0;
        acc.indirect += value;
      }
      // 3. Update solicited value
      acc.solicited += curr.val_consegnato_unique;
      return acc;
    },
    {direct: 0, indirect: null, solicited: 0}
  );
  return indirect !== null
    ? (direct + indirect) / solicited
    : null;
}