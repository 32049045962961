export const MONTHS_CHOICES = {
  1: "Ultimo mese",
  3: "Ultimi 3 mesi",
  6: "Ultimi 6 mesi",
  12: "Ultimi 12 mesi",
}
export const OUTLOOK_CHOICES = {
  5: "5 giorni",
  10: "10 giorni",
  20: "20 giorni",
}
