import MaterialTable from "material-table";
import {tableIcons} from "../../../../../ui-components/TableIcons";
import React from "react";
import {COLUMNS, OPTIONS, LOCALIZATION} from "./lib/consts"

export const Table = ({ data }) => (
  <MaterialTable
    columns={COLUMNS}
    data={data}
    icons={tableIcons}
    options={OPTIONS}
    localization={LOCALIZATION}
  />
)